import { createRef, useEffect, useState } from "react"
import { Box, Typography, useMediaQuery } from "@mui/material"
import { createSearchParams, Link } from "react-router-dom"

import ApplicationLayout from "components/views/application/Layout"
import StepsLayout from "components/views/application/steps/Layout"
import Progress from "components/shared/Progress"
import GeneralInfo from "./GeneralInfo"
import Documents from "./Documents"
import Photos from "./Photos"

import BackButton from "components/shared/buttons/Back"
import SubmitButton from "components/shared/buttons/Submit"

import useAppStore from "store"
import useNavigateSearch from "hooks/use_navigate_search"
import useClientStore from "store/client"

const Step2 = () => {
  const steps = useAppStore((state) => state.steps)
  const [disabled, setDisabled] = useState(true)
  const getAppStep = useAppStore((state) => state.getAppStep)
  const formRef = createRef()
  const utmParams = useClientStore((state) => state.utm_params)

  useEffect(() => {
    window.scrollTo(0, 0)

    const valid = steps["car_general_info"] && steps["car_documents"]
    setDisabled(!valid)
  }, [steps])

  const navigateSearch = useNavigateSearch()

  useEffect(() => {
    getAppStep().then((response) => {
      const step = response?.data?.current_step

      switch (step) {
        case null:
          navigateSearch("/autoloan/technical_error")
          break
        case "1":
          navigateSearch("/autoloan/application/steps/1")
          break
        case "success":
          navigateSearch("/autoloan/application/success")
          break
        case "reject":
          navigateSearch("/autoloan/application/reject")
          break
        default:
          window.scrollTo(0, 0)

          if (typeof window.dataLayer !== "undefined" && window.dataLayer !== null) {
            window.dataLayer.push({ event: "step1_completed" })
          }
      }
    })
  }, [])

  const handleSubmit = () => {
    formRef.current.requestSubmit()
  }

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"))

  return (
    <ApplicationLayout>
      <Progress />
      <StepsLayout>
        <Typography variant="h3" component="h3" sx={{ mb: { xs: 2, md: 2.5 } }}>
          Car details step
        </Typography>

        <Box sx={{ mx: { xs: -2, md: 0 } }}>
          <GeneralInfo />
          <Documents />
          <Photos ref={formRef} />
        </Box>

        <Box sx={{ mt: 3.5, display: "flex", justifyContent: "space-between" }}>
          <Link
            to={{
              pathname: "/autoloan/application/steps/1",
              search: `?${createSearchParams(utmParams)}`,
            }}
          >
            <BackButton text={matches ? "Previous step" : "Previous"} />
          </Link>
          <SubmitButton text="Finish Application" disabled={disabled} onClick={handleSubmit} />
        </Box>
      </StepsLayout>
    </ApplicationLayout>
  )
}

export default Step2
