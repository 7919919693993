import { Link, Typography } from "@mui/material"

const PrivacyPolicy = () => {
  return (
    <Typography variant="body2" component="p" sx={{ mt: 2 }}>
      Clicking &apos;Take money&apos; signifies your acknowledgment and agreement with our&nbsp;
      <Link
        component="a"
        target="_blank"
        color="secondary"
        href="/autoloan/PRIVACY_POLICY_KREDDY.pdf"
        sx={{ textDecoration: "none" }}
      >
        Privacy Policy
      </Link>
      &nbsp;and&nbsp;
      <Link
        component="a"
        target="_blank"
        color="secondary"
        href="/autoloan/TERMS&CONDITIONS_KREDDY.pdf"
        sx={{ textDecoration: "none" }}
      >
        Terms & Conditions
      </Link>
      . You must agree to these terms to continue.
    </Typography>
  )
}

export default PrivacyPolicy
