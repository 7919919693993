import { BrowserRouter, Route, Routes } from "react-router-dom"

import theme from "config/theme"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"

import HomePage from "components/views/Home"
import Step1 from "components/views/application/steps/1"
import Step2 from "components/views/application/steps/2"
import Success from "components/views/application/result/Success"
import Reject from "components/views/application/result/Reject"
import Waiting from "components/views/application/result/Waiting"
import Backdrop from "components/shared/Backdrop"
import PageNotFound from "components/views/404"
import TechnicalError from "components/views/500"

const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Backdrop />
    <BrowserRouter>
      <Routes>
        <Route path="/autoloan">
          <Route path="" element={<HomePage />} />
          <Route path="application/steps/1" element={<Step1 />} />
          <Route path="application/steps/2" element={<Step2 />} />
          <Route path="application/success" element={<Success />} />
          <Route path="application/reject" element={<Reject />} />
          <Route path="application/waiting" element={<Waiting />} />
          <Route path="technical_error" element={<TechnicalError />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
)

export default App
