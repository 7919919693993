import { forwardRef } from "react"

import { Box, Grid } from "@mui/material"

import useAppRequest from "hooks/use_app_request"
import useNavigateSearch from "hooks/use_navigate_search"

import StepBlock from "components/shared/StepBlock"
import Occupation from "components/shared/fields/Occupation"
import MonthlyIncome from "components/shared/fields/MonthlyIncome"
import Tin from "components/shared/fields/Tin"
import Payslip from "components/shared/fields/Payslip"
import CompanyPhoneNumber from "components/shared/fields/CompanyPhoneNumber"
import CompanyName from "components/shared/fields/CompanyName"
import RegularDocument from "components/shared/fields/RegularDocument"
import useAppStore from "../../../../../store"

const FinancialInfo = forwardRef((props, ref) => {
  const stepName = "financial_info"

  const { handleSubmit } = useAppRequest(stepName, {
    occupation_id: "social_status_id",
    tin_document_number: "tin_document_number",
    monthly_income: "salary",
    company_name: "company_name",
    company_phone: "company_phone",
    payslip: "payslip",
    regular_document: "regular_document",
  })

  const occupation_id = useAppStore((state) => state.fields?.occupation_id)

  const navigateSearch = useNavigateSearch()

  const stepSubmit = (event) => {
    const callback = () => navigateSearch("/autoloan/application/steps/2")

    handleSubmit(event, callback)
  }

  return (
    <StepBlock stepName={stepName} stepTitle="Financial info">
      <Box component="form" ref={ref} onSubmit={stepSubmit}>
        <Box sx={{ maxWidth: "388px" }}>
          <Occupation />
          <CompanyName />
          <CompanyPhoneNumber />
          <Tin />
          <MonthlyIncome />
          <Grid container columnSpacing={2} sx={{ mt: 0, mb: 2.5 }}>
            <Grid item xs={6}>
              <Payslip />
            </Grid>
            <Grid item xs={6}>
              {[9, 10].includes(occupation_id) && <RegularDocument />}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </StepBlock>
  )
})

FinancialInfo.displayName = "FinancialInfo"

export default FinancialInfo
