import { Box, Container, Typography } from "@mui/material"

import ApplicationLayout from "./application/Layout"
import Image from "static/404_olp.png"

const PageNotFound = () => {
  return (
    <ApplicationLayout>
      <Container maxWidth="lg">
        <Box
          sx={{
            height: { xs: 600, xl: 800 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: { xs: 142, md: 296 },
              width: { xs: 328, md: 681 },
            }}
            alt="Wheels"
            src={Image}
          />
          <Typography variant="h3" sx={{ mt: 7, textAlign: "center" }}>
            Sorry, the page not found
          </Typography>
        </Box>
      </Container>
    </ApplicationLayout>
  )
}

export default PageNotFound
