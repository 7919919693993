import {
  Container,
  Box,
  Card,
  Paper,
  Typography,
  Button,
  ButtonGroup,
  useMediaQuery,
} from "@mui/material"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import review01 from "static/reviews/review_01.png"
import review02 from "static/reviews/review_02.png"
import review03 from "static/reviews/review_03.png"
import review04 from "static/reviews/review_04.png"
import review05 from "static/reviews/review_05.png"

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"

const steps = [
  {
    src: review01,
    title: "Francine Javier",
    description:
      "Matagal na kong hirap na hirap mag budget at wala akong mahiraman pero nung sinubukan ko si Kreddy, talagang napahanga ako. Ang aplikasyon ay diretso, at nakuha ko ang pondong kailangan ko sa lalong madaling panahon. At ang patuloy na paggamit ko ng aking sasakyan kahit nakasangla ay isang malaking ginhawa. Salamat sa tulong niyo sa kagaya ko!",
  },
  {
    src: review02,
    title: "Kenneth Robles",
    description:
      "I can't thank Kreddy enough for helping me out during a tough spot. They made the process so simple and hassle-free. They were way better than I expected! I will surely recommend them.",
  },
  {
    src: review03,
    title: "Ellis Sabado",
    description:
      "Tuwang-tuwa ako sa naging karanasan ko kay Kreddy! Bilang single mom, kailangan ko ng dagdag na pera para mabayarn ang mga hindi inaasahang gastusin, at si Kreddy lang ang walang pagaalinlangang tumulong sakin. Napakadali ng proseso, napakabait at madaling kausap ng kanilang empleyado. Talagang irerekomenda ko sila sa aking pamilya at mga kaibigan!",
  },
  {
    src: review04,
    title: "Janice Enriquez",
    description:
      "I was skeptical at first, but Kreddy exceeded all my expectations! I am really happy how fast they offered their assistance when I needed funds. Terms were flexible, making it easy for me to manage. The customer service was top-notch too. If you're in need of quick cash, Kreddy is the BEST!",
  },
  {
    src: review05,
    title: "Benny Morales",
    description:
      "Sobrang laking pasasalamat ko kay Kreddy sa pagtulong sa akin sa panahong sobrang gipit ako. Ginawa nilang napakasimple at walang problema ang proseso. Mas mahusay sila kesa sa inaasahan ko. Tiyak na irerekomenda ko sila!",
  },
]

// eslint-disable-next-line no-unused-vars
const Buttons = ({ next, previous, goToSlide, carouselState }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"))

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mb: { md: 4, xs: 2 },
        mx: { md: 1, xs: 0 },
      }}
    >
      <Typography variant="h3">Reviews</Typography>
      {matches && (
        <ButtonGroup>
          <Button
            onClick={() => previous()}
            color="inherit"
            sx={{ color: "#E0E0E7", borderColor: "#E0E0E7", py: 0.5, px: 0 }}
          >
            <KeyboardArrowLeftIcon />
          </Button>
          <Button
            onClick={() => next()}
            color="inherit"
            sx={{ color: "#E0E0E7", borderColor: "#E0E0E7", py: 0.5, px: 0 }}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </ButtonGroup>
      )}
    </Box>
  )
}

// eslint-disable-next-line no-unused-vars
const Dots = ({ onMove, index, onClick, active }) => {
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <Box
      component="li"
      sx={{
        width: { xs: 12, md: 16 },
        height: { xs: 12, md: 16 },
        backgroundColor: active ? "primary.main" : "bg.main",
        borderRadius: "50%",
        mx: 2,
        position: "relative",
        mb: 0,
      }}
      onClick={() => onClick()}
    />
  )
}

const Reviews = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"))

  return (
    <Container maxWidth="xl">
      <Paper
        id="reviwes"
        elevation={0}
        sx={{
          pb: { xs: 6, lg: 13 },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column-reverse", mx: { md: -1, xs: 0 } }}>
          <Carousel
            autoPlay={false}
            showDots={!matches}
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            arrows={false}
            renderButtonGroupOutside={true}
            customButtonGroup={<Buttons />}
            customDot={<Dots />}
            responsive={responsive}
          >
            {steps.map((item) => (
              <Card
                orientation="horizontal"
                size="sm"
                key={item.title}
                variant="outlined"
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  height: matches ? "90%" : "95%",
                  mx: { md: 1, xs: 0 },
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: { xs: "100%", lg: "45%" },
                    maxWidth: { lg: 330 },
                    objectFit: "cover",
                  }}
                  alt={item.title}
                  src={item.src}
                />
                <Box sx={{ p: 3 }}>
                  <Typography variant="h5" sx={{ fontSize: "22px", fontWeight: 700, mb: 1 }}>
                    {item.title}
                  </Typography>
                  <Typography variant="h6" sx={{ lineHeight: "26px", mb: 4 }}>
                    {item.description}
                  </Typography>
                </Box>
              </Card>
            ))}
          </Carousel>
        </Box>
      </Paper>
    </Container>
  )
}

export default Reviews
