import { create } from "zustand"
import { persist } from "zustand/middleware"
import $api from "config/api"
import useClientStore from "./client"

const initialState = {
  uuid: "",
  current_step: "",
  backdrop: false,
  otp_waiting_seconds: 0,
  otp_confirmed: false,
  status: "pending",

  fields: {
    amount: "",
    term: "",
    mobile_phone: "",
    full_name: "",
    date_of_birth: "",
    gender: "",
    email: "",
    reference1_id: "",
    reference1_full_name: "",
    reference1_phone_number: "",
    reference2_id: "",
    reference2_full_name: "",
    reference2_phone_number: "",
    living_city_id: "",
    living_address: "",
    document_type: "",
    document_number: "",
    occupation_id: "",
    monthly_income: "",
    tin_document_number: "",
    company_name: "",
    company_phone_number: "",
    // bank_id: "",
    // bank_account_number: "",
    // repayment_method: "",
    car_model: "",
    car_year: "",
    car_plate_number: "",
    car_mileage: "",
    otp_code: "",
  },
  error_messages: {
    amount: "",
    term: "",
    mobile_phone: "",
    full_name: "",
    date_of_birth: "",
    gender: "",
    email: "",
    viber_phone: "",
    facebook_link: "",
    loan_purpose: "",
    reference1_id: "",
    reference1_full_name: "",
    reference1_phone_number: "",
    reference2_id: "",
    reference2_full_name: "",
    reference2_phone_number: "",
    living_city_id: "",
    living_address: "",
    living_bill: "",
    document_type: "",
    document_number: "",
    front_document: "",
    selfie_document: "",
    occupation_id: "",
    tin_document_number: "",
    monthly_income: "",
    company_name: "",
    company_phone: "",
    payslip: "",
    regular_document: "",
    // bank_id: "",
    // bank_account_number: "",
    // repayment_method: "",
    car_model: "",
    car_year: "",
    car_plate_number: "",
    car_mileage: "",
    car_document_or: "",
    car_document_cr: "",
    car_photos: "",
    otp_code: "",
  },
  steps: {
    general_info: null,
    references: null,
    residence: null,
    government_issued_id: null,
    financial_info: null,
    car_general_info: null,
    car_documents: null,
    car_photos: null,
  },
}

const useAppStore = create(
  persist(
    (set, get) => ({
      ...initialState,

      resetState: () => set(initialState),

      // Keys operations
      updateKey: (key, value) => set((state) => ({ ...state }, { [key]: value })),
      updateField: (key, value) => set((state) => ({ fields: { ...state.fields, [key]: value } })),
      updateErrorMessage: (key, value) =>
        set((state) => ({ error_messages: { ...state.error_messages, [key]: value } })),

      // Step operations
      makeStepSuccess: (key) => set((state) => ({ steps: { ...state.steps, [key]: true } })),
      makeStepFailed: (key) => set((state) => ({ steps: { ...state.steps, [key]: false } })),
      updateCurrentStep: (panel) => set((state) => ({ ...state }, { current_step: panel })),
      getNextStep: () => {
        const steps = get().steps

        const nextStep =
          Object.keys(steps).find((key) => steps[key] === false) ||
          Object.keys(steps).find((key) => steps[key] === null)

        return nextStep
      },

      // Application handbook
      getDocumentTypes: () => $api.get("/info/document_types"),
      getSocialStatuses: () => $api.get("/info/social_statuses"),
      getBanks: () => $api.get("/info/banks"),
      getGenders: () => $api.get("/info/genders"),
      getDisbursementChannels: () => $api.get("/info/disbursement_channels"),
      getReferences: () => $api.get("/info/references"),

      // Application
      createApp: () =>
        $api.post("/applications", {
          amount: get().fields.amount,
          term: get().fields.term,
          mobile_phone: get().fields.mobile_phone,
          utm_params: useClientStore.getState().utm_params,
        }),

      updateApp: (params) => $api.patch(`/applications/${get().uuid}`, params),
      getAppStep: () => $api.get(`/applications/${get().uuid}/current_step`),

      // App references
      updateAppReferences: (params) => $api.patch(`/applications/${get().uuid}/references`, params),

      // Application documents
      getDocumentPhoto: (document_type) =>
        $api.get(`/applications/${get().uuid}/documents`, { params: { document_type } }),
      createDocumentPhoto: (document_type, file) => {
        const formData = new FormData()

        formData.append("document_type", document_type)
        formData.append("file", file)

        return $api.post(`/applications/${get().uuid}/documents`, formData)
      },
      removeDocumentPhoto: (document_type) =>
        $api.delete(`/applications/${get().uuid}/documents`, { params: { document_type } }),

      // Application geolocation
      updateAppGeolocation: (lon, lat) =>
        $api.post(`/applications/${get().uuid}/geolocation`, { lon, lat }),

      // Car photos
      getCarPhotos: () => $api.get(`/applications/${get().uuid}/car_photos`),
      createCarPhoto: (files) => {
        const formData = new FormData()

        for (const item of files) {
          formData.append("photos[]", item)
        }

        return $api.post(`/applications/${get().uuid}/car_photos`, formData)
      },
      removeCarPhoto: (name) =>
        $api.delete(`/applications/${get().uuid}/car_photos/delete/${name}`),

      // Car documents
      getCarDocumentPhoto: (document_type) =>
        $api.get(`/applications/${get().uuid}/car_documents`, { params: { document_type } }),
      createCarDocumentPhoto: (document_type, file) => {
        const formData = new FormData()

        formData.append("document_type", document_type)
        formData.append("file", file)

        return $api.post(`/applications/${get().uuid}/car_documents`, formData)
      },
      removeCarDocumentPhoto: (document_type) =>
        $api.delete(`/applications/${get().uuid}/car_documents`, { params: { document_type } }),

      // Application OTP
      sendOtp: () => $api.post("/applications/otp/send", { uuid: get().uuid }),
      checkOtp: () =>
        $api.get("/applications/otp/check", {
          params: {
            uuid: get().uuid,
            otp_code: get().fields.otp_code,
          },
        }),
    }),
    {
      name: "application",
    },
  ),
)

export default useAppStore
