import { useState } from "react"
import { Box } from "@mui/material"

import Gallery from "components/shared/Gallery"
import { forwardRef } from "react"
import OtpModal from "./OtpModal"
import useAppRequest from "hooks/use_app_request"
import StepBlock from "components/shared/StepBlock"
import useAppStore from "../../../../../store"

const Photos = forwardRef((props, ref) => {
  const stepName = "car_photos"
  const sendOtp = useAppStore((state) => state.sendOtp)
  const updateKey = useAppStore((state) => state.updateKey)

  const [open, setOpen] = useState(false)
  const handleOpenModal = () => setOpen(true)
  const handleCloseModal = () => setOpen(false)

  const { handleSubmit } = useAppRequest(stepName, {
    car_photos: "car_photos",
  })

  const stepSubmit = (event) => {
    const callback = () => {
      sendOtp().then((response) => {
        const { waiting_seconds } = response.data
        updateKey("otp_waiting_seconds", waiting_seconds)
        handleOpenModal()
      })
    }

    handleSubmit(event, callback)
  }

  return (
    <StepBlock stepName={stepName} stepTitle="Photos">
      <Box component="form" onSubmit={stepSubmit} ref={ref}>
        <Gallery />
        <OtpModal open={open} handleClose={handleCloseModal} />
      </Box>
    </StepBlock>
  )
})

Photos.displayName = "Photos"

export default Photos
