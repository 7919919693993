import ApplicationLayout from "../../Layout"
import { Box, Container, Typography } from "@mui/material"
import Image from "static/reject_page_olp.png"
import { useEffect } from "react"
import useAppStore from "store"
import useNavigateSearch from "hooks/use_navigate_search"

const Reject = () => {
  const getAppStep = useAppStore((state) => state.getAppStep)
  const navigateSearch = useNavigateSearch()

  useEffect(() => {
    getAppStep().then((response) => {
      const step = response?.data?.current_step

      switch (step) {
        case null:
          navigateSearch("/autoloan/technical_error")
          break
        case "success":
          navigateSearch("/autoloan/application/success")
          break
        case "1":
          navigateSearch("/autoloan/application/steps/1")
          break
        case "2":
          navigateSearch("/autoloan/application/steps/2")
          break
        default:
          window.scrollTo(0, 0)

          if (typeof window.dataLayer !== "undefined" && window.dataLayer !== null) {
            window.dataLayer.push({ event: "step2_completed" })
            window.dataLayer.push({ event: "status_rejected" })
          }
      }
    })
  }, [])

  return (
    <ApplicationLayout>
      <Container maxWidth="lg">
        <Box
          sx={{
            height: { xs: 600, xl: 800 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: { xs: 146, md: 337 },
              width: { xs: 390, md: 907 },
            }}
            alt="Documents"
            src={Image}
          />
          <Typography
            variant="h3"
            sx={{ maxWidth: { xs: 300, md: 360 }, mt: 8, textAlign: "center" }}
          >
            Your application has been rejected
          </Typography>
        </Box>
      </Container>
    </ApplicationLayout>
  )
}

export default Reject
