import { Box } from "@mui/material"

import useAppRequest from "hooks/use_app_request"

import StepBlock from "components/shared/StepBlock"
import LivingAddress from "components/shared/fields/LivingAddress"
import LivingBill from "components/shared/fields/LivingBill"
import SecondaryButton from "components/shared/buttons/Secondary"
import { useEffect } from "react"
import useAppStore from "../../../../../store"

const Residence = () => {
  const stepName = "residence"

  const { handleSubmit } = useAppRequest(stepName, {
    living_address: "living_street_house_apt",
    utility_bill: "utility_bill",
  })

  const updateAppGeolocation = useAppStore((state) => state.updateAppGeolocation)

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords

          updateAppGeolocation(longitude, latitude).catch((e) => console.log(e.message))
        },
        (error) => console.error("Error getting user location:", error),
      )
    } else {
      console.error("Geolocation is not supported by this browser.")
    }
  }, [])

  return (
    <StepBlock stepName={stepName} stepTitle="Residence">
      <Box component="form" onSubmit={handleSubmit}>
        <Box sx={{ maxWidth: "388px" }}>
          <LivingAddress />
          <LivingBill />

          <SecondaryButton text="Next" type="submit" />
        </Box>
      </Box>
    </StepBlock>
  )
}

export default Residence
