import ApplicationLayout from "../../Layout"
import { Box, Container, Typography } from "@mui/material"
import Image from "static/success_page_olp.png"
import useAppStore from "../../../../../store"
import { useEffect } from "react"
import useNavigateSearch from "hooks/use_navigate_search"

const PaymentDetails = () => {
  const amount = useAppStore((state) => state.fields.amount)
  const term = useAppStore((state) => state.fields.term)

  return (
    <Box
      sx={{
        mt: 4,
        backgroundColor: "#F8F8FA",
        border: "1px solid #DBDBE5",
        borderRadius: "4px",
        width: { xs: "80%", md: "auto" },
        py: 3,
        px: 4,
      }}
    >
      <Typography variant="body2" sx={{ fontFamily: "inter", mb: 1.5, color: "#49546B" }}>
        Details of your application:
      </Typography>
      <Box sx={{ display: "flex", mb: 1 }}>
        <Typography variant="body1" sx={{ width: "70px" }}>
          Amount
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 700, textTransform: "uppercase" }}>
          PHP {amount}
        </Typography>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Typography variant="body1" sx={{ width: "70px" }}>
          Term
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 700, textTransform: "uppercase" }}>
          {term} month
        </Typography>
      </Box>
    </Box>
  )
}

const Success = () => {
  const getAppStep = useAppStore((state) => state.getAppStep)
  const navigateSearch = useNavigateSearch()

  useEffect(() => {
    getAppStep().then((response) => {
      const step = response?.data?.current_step

      switch (step) {
        case null:
          navigateSearch("/technical_error")
          break
        case "1":
          navigateSearch("/autoloan/application/steps/1")
          break
        case "2":
          navigateSearch("/autoloan/application/steps/2")
          break
        case "reject":
          navigateSearch("/autoloan/application/reject")
          break
        default:
          window.scrollTo(0, 0)

          if (typeof window.dataLayer !== "undefined" && window.dataLayer !== null) {
            window.dataLayer.push({ event: "step2_completed" })
            window.dataLayer.push({ event: "status_accepted" })
          }
      }
    })
  }, [])

  return (
    <ApplicationLayout>
      <Container maxWidth="lg">
        <Box
          sx={{
            height: { xs: 600, md: 800 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: { xs: 147, md: 337 },
              width: { xs: 416, md: 907 },
            }}
            alt="Documents"
            src={Image}
          />
          <Typography
            variant="h3"
            sx={{ maxWidth: { xs: 300, md: 360 }, mt: 8, textAlign: "center" }}
          >
            Application accepted, we will contact you
          </Typography>

          <PaymentDetails />
        </Box>
      </Container>
    </ApplicationLayout>
  )
}

export default Success
