import { useState } from "react"
import { parseNum } from "config/helper"
import { Box, Card, Divider, Typography } from "@mui/material"

import MobilePhone from "components/shared/fields/MobilePhone"
import SubmitButton from "components/shared/buttons/Submit"
import BaseSlider from "components/base/Slider"
import useAppStore from "store"
import PrivacyPolicy from "./PrivacyPolicy"
import useNavigateSearch from "../../../hooks/use_navigate_search"

const Calculator = () => {
  const [currentAmount, setCurrentAmount] = useState(500000)
  const [minAmount] = useState(100000)
  const [maxAmount] = useState(2000000)
  const [amountStep] = useState(100000)

  const [currentTerm, setCurrentTerm] = useState(14)
  const [minTerm] = useState(12)
  const [maxTerm] = useState(24)
  const [termStep] = useState(1)

  const updateKey = useAppStore((state) => state.updateKey)
  const updateErrorMessage = useAppStore((state) => state.updateErrorMessage)
  const updateField = useAppStore((state) => state.updateField)
  const errorMessages = useAppStore((state) => state.error_messages)
  const createApp = useAppStore((state) => state.createApp)
  const navigateSearch = useNavigateSearch()

  const handleChangeAmount = (e, value) => {
    setCurrentAmount(value)
  }

  const handleChangeTerm = (e, value) => {
    setCurrentTerm(value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (errorMessages["mobile_phone"].length > 0) return

    updateField("amount", currentAmount)
    updateField("term", currentTerm)

    updateKey("backdrop", true)

    createApp()
      .then((response) => response?.data)
      .then((data) => {
        updateKey("uuid", data?.uuid)
        updateKey("current_step", "general_info")

        navigateSearch("/autoloan/application/steps/1")
      })
      .catch((error) => {
        const data = error.response.data

        if (data.error_messages) {
          const error =
            (data.error_messages.mobile_phone && data.error_messages.mobile_phone[0]) || ""

          if (error === "Unknown number") {
            navigateSearch("/autoloan/application/success")
          } else {
            updateErrorMessage("mobile_phone", error)
          }
        }
      })
      .finally(() => {
        updateKey("backdrop", false)
      })
  }

  return (
    <Card elevation={2} sx={{ borderRadius: "10px", width: { md: "450px" } }}>
      <Box sx={{ px: { xs: 2.5, md: 3.5 }, py: { xs: 2, md: 3 } }}>
        <Typography variant="h4" sx={{ mt: 1 }}>
          Loan calculator
        </Typography>
      </Box>
      <Divider />

      <Box sx={{ px: { xs: 2.5, md: 3.5 }, py: { xs: 2, md: 3 } }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: { xs: -1, md: 0.5 } }}>
          <Typography variant="h6">Amount</Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            PHP {parseNum(currentAmount)}
          </Typography>
        </Box>
        <BaseSlider
          color="primary"
          aria-label="Always visible"
          value={currentAmount}
          step={amountStep}
          min={minAmount}
          max={maxAmount}
          onChange={handleChangeAmount}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: { xs: -1.5, md: -0.5 } }}>
          <Typography variant="caption" color="text.secondary">
            PHP {parseNum(minAmount)}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            PHP {parseNum(maxAmount)}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: { xs: -1, md: 0.5 },
            mt: 2.5,
          }}
        >
          <Typography variant="h6">Term</Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {currentTerm} MONTH
          </Typography>
        </Box>
        <BaseSlider
          color="primary"
          aria-label="Always visible"
          value={currentTerm}
          step={termStep}
          min={minTerm}
          max={maxTerm}
          onChange={handleChangeTerm}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: { xs: -1.5, md: -0.5 } }}>
          <Typography variant="caption" color="text.secondary">
            12 MONTH
          </Typography>
          <Typography variant="caption" color="text.secondary">
            24 MONTH
          </Typography>
        </Box>
      </Box>
      <Divider />

      <Box
        sx={{
          px: { xs: 2.5, md: 3.5 },
          pb: 2,
          pt: 2.5,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          Interest per month
        </Typography>
        <Typography variant="h6">ONLY 1.8%</Typography>
      </Box>
      <Divider />

      <Box
        sx={{
          px: { xs: 2.5, md: 3.5 },
          pt: { xs: 1.5, md: 2 },
          pb: { xs: 3, md: 3.5 },
        }}
        component="form"
        onSubmit={handleSubmit}
      >
        <MobilePhone />

        <SubmitButton text="Take money" fullWidth type="submit" />

        <PrivacyPolicy />
      </Box>
    </Card>
  )
}

export default Calculator
