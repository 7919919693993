import useAppStore from "store"
import ENABLE_VALIDATIONS from "config/validations"

const useAppRequest = (stepName, paramsMapper) => {
  // paramsMapper(need to save data and sync server validation errors)
  // key = front param name, value = server param name

  const errorMessages = useAppStore((state) => state.error_messages)
  const fields = useAppStore((state) => state.fields)

  const makeStepSuccess = useAppStore((state) => state.makeStepSuccess)
  const makeStepFailed = useAppStore((state) => state.makeStepFailed)
  const updateCurrentStep = useAppStore((state) => state.updateCurrentStep)
  const getNextStep = useAppStore((state) => state.getNextStep)

  const updateApp = useAppStore((state) => state.updateApp)
  const updateAppReferences = useAppStore((state) => state.updateAppReferences)
  const updateErrorMessage = useAppStore((state) => state.updateErrorMessage)

  const validate = () => {
    const valid = Object.keys(paramsMapper)
      .map((field) => !errorMessages[field])
      .every((v) => v === true)

    if (!valid && ENABLE_VALIDATIONS) {
      makeStepFailed(stepName)
    }

    return valid
  }

  const buildParams = () => {
    const nestedParams = {}

    Object.entries(paramsMapper).map((arr) => {
      const [key, value] = arr

      nestedParams[value] = fields[key]
    })

    return {
      step_name: stepName,
      [stepName]: nestedParams,
    }
  }

  const setErrorMessages = (messages) => {
    Object.entries(paramsMapper).map((arr) => {
      const [key, value] = arr

      updateErrorMessage(key, (messages[value] && messages[value][0]) || "")
    })
  }

  const handleSubmit = (event, callback) => {
    event.preventDefault()

    if (!validate()) return

    const params = buildParams()

    return updateApp(params)
      .then(() => {
        makeStepSuccess(stepName)
        updateCurrentStep(getNextStep())

        callback()
      })
      .catch((error) => {
        const messages = error?.response?.data?.error_messages
        console.log(messages)

        if (messages) {
          setErrorMessages(messages)

          makeStepFailed(stepName)
        }
      })
  }

  const handleSubmitReferences = (event, callback) => {
    event.preventDefault()

    if (!validate()) return

    const params = buildParams()

    return updateAppReferences(params)
      .then(() => {
        makeStepSuccess(stepName)
        updateCurrentStep(getNextStep())

        callback()
      })
      .catch((error) => {
        const messages = error?.response?.data?.error_messages

        if (messages) {
          setErrorMessages(messages)

          makeStepFailed(stepName)
        }
      })
  }

  return {
    handleSubmit,
    handleSubmitReferences,
  }
}

export default useAppRequest
