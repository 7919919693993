import { Box, Container, Typography } from "@mui/material"

import ApplicationLayout from "./application/Layout"
import Image from "static/500_olp.png"

const TechnicalError = () => {
  return (
    <ApplicationLayout>
      <Container maxWidth="lg">
        <Box
          sx={{
            height: { xs: 600, xl: 800 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: { xs: 205, md: 373 },
              width: { xs: 264, md: 480 },
            }}
            alt="Wheels"
            src={Image}
          />
          <Typography variant="h3" sx={{ mt: 4.5, textAlign: "center" }}>
            The service is unavailable
          </Typography>
          <Typography variant="h6" sx={{ mt: 2, textAlign: "center" }}>
            Technical work is underway, please visit the site later
          </Typography>
        </Box>
      </Container>
    </ApplicationLayout>
  )
}

export default TechnicalError
